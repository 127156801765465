import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
const Index = {
  name: 'purchase-order',
  mixins: [tableMixin, root],
  components: {
    PageHeader,
    Adsearch
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  data() {
    return {
      root_name: 'cgdd',
      root_msg: '采购订单',
      orderNumType: 1,
      orderNumClass: 'hederleft cursor',
      orderNumClassActive: 'orderheadactive hederleft cursor',
      ordderNumList: [],
      formSearch: {
        SelectType: '1',
        KeyWord: '',
        CompanyID: '',
        BeginDate: '',
        EndDate: '',
        SapPR: '', // 采购申请单
        OrderID: '', // 订单编号
        OrderPurchaseSapID: '', // 采购订单编号,
        SourceOrderCompanyName: '', // 来源客户名称
        SAPSupplierCompanyName: '', // 供应商名称
        CreateUserName: '', // 创建人
        DateCreate: [],
        PageIndex: '',
        PageSize: ''
      },
      formSearchList: {
        SelectType: '1',
        KeyWord: '',
        CompanyID: '',
        BeginDate: '',
        EndDate: '',
        SapPR: '', // 采购申请单
        OrderID: '', // 订单编号
        OrderPurchaseSapID: '', // 采购订单编号,
        SourceOrderCompanyName: '', // 来源客户名称
        SAPSupplierCompanyName: '', // 供应商名称
        CreateUserName: '', // 创建人
        DateCreate: [],
        PageIndex: '',
        PageSize: ''
      },
      isShow: false,
      adSearchForm: [
        { name: '采购订单编号', value: 'OrderPurchaseSapID' },
        { name: '供应商订单号', value: 'OrderID' },
        { name: '采购申请编号', value: 'SapPR' },
        { name: '订单创建人', value: 'CreateUserName' },
        { name: '创建日期', value: 'DateCreate', date: true },
        { name: '来源客户名称', value: 'SourceOrderCompanyName' },
        { name: '供应商名称', value: 'SAPSupplierCompanyName' }
      ],
      visible: false,
      showRemake: false,
      selects: [],
      OrderPurchaseSapID: '',
      isRemake: '',
      PurchaseOrderID: [] // 采购单ID集合
    }
  },
  watch: {
    selects(a) {
      this.PurchaseOrderID = []
      if (a.length <= 0) return this.PurchaseOrderID = []
      a.map(item => {
        this.PurchaseOrderID.push(item.OrderPurchaseSapID)
      })
    }
  },
  mounted() {
    this.GetOrderPurchaseStatistical()
  },
  methods: {
    searchAd() {
      if (this.formSearch.DateCreate.length > 0) {
        this.formSearch.BeginDate = this.formSearch.DateCreate[0]
        this.formSearch.EndDate = this.formSearch.DateCreate[1]
      }
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      this.getTableDataAsync()
      this.isShow = false
      // this.formSearch = this.formSearchList
    },
    sueRemake() {
      if (!this.isRemake) return this.$message.error('删除备注不可为空')
      this.$api.DelePurchaseOrder_All({
        OrderPurchaseSapID: this.OrderPurchaseSapID,
        Remark: this.isRemake
      }).then(res => {
        console.log(res)
        this.showRemake = false
        if (res.RetCode === '0') {
          this.getTableDataAsync()
          return this.$message.success('删除成功')
        }
        return this.$message.error('删除失败: ' + res.RetMsg)
      })
    },
    // 下载导入模板
    downLoadFileTemplate() {
      window.location.href = 'https://ivdapi.mai47.com/Static/ImportPreGRN.xlsx'
    },
    getFile() {
      const _this = this
      const inputFile = this.$refs.filElem.files[0]
      if (inputFile) {
        const fileName = inputFile.name.split('.')[inputFile.name.split('.').length - 1]
        if (fileName !== 'xlsx') return _this.$message.error('请上传导入模板')
        const reader = new FileReader()
        reader.readAsDataURL(inputFile)
        reader.onload = function(e) {
          console.log('imgInfo', e.target.result)
          _this.$api.ExportPurchaesOrderInfo({
            FileData: e.target.result,
            FileType: fileName
          }).then(res => {
            if (res.RetCode === '0') {
              _this.getTableDataAsync()
              return _this.$message.success('导入成功')
            }
            return _this.$message.error(res.RetMsg)
          })
        }
      } else {
        return
      }
    },
    importFileExcle() {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    // 导入预入库单
    // importFileExcle() {
    //   this.$api.ExportPurchaesOrderInfo({
    //     file: ''
    //   }).then(res => {
    //     console.log(res)
    //   })
    // },
    selectAll(e) {
      console.log(e) // MaterialLotNo
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    // 采购单导出
    OrderPurchaseExport() {
      // if (this.selects.length <= 0) return this.$message.error('没有任何选中')
      this.formSearch.SelectIDList = this.PurchaseOrderID
      this.$refs.ExportLoading.open()
      this.$api.ExportGetPurchaseOrderList(this.formSearch).then(res => {
        if (res.RetCode === '0') {
          const name = this.$minCommon.getExportName('采购单收货')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return this.$message.error('错误：', res.Msg)
      })
    },
    // 测试change
    testChange(filterObj) {
      this.currentPage = 1
      let Status = ''
      console.log(filterObj)
      filterObj.PurchaseState.forEach(item => {
        Status += `${item},`
      })
      this.formSearch.SelectType = Status
      this.getTableDataAsync()
    },
    // 删除整单
    DelePurchaseOrder_All(row) {
      console.log(row)
      this.OrderPurchaseSapID = row.OrderPurchaseSapID
      this.showRemake = true
    },
    changeType(type) {
      this.orderNumType = type
      this.formSearch.SelectType = type
      this.getTableDataAsync()
    },
    Adsearch() {
      this.isShow = true
    },
    selectType(type) {
    },
    // 采购单统计
    GetOrderPurchaseStatistical() {
      this.$api.GetOrderPurchaseStatistical().then(res => {
        console.log(res)
        this.ordderNumList = res.Message
      })
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.GetPurchaseOrderList(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      // this.$nextTick(() => { // 在数据加载完，重新渲染表格
      //   this.$refs.multipleTable.doLayout()
      // })
      console.log(response)
      return response
    },
    filterHandler(value, row, column) {
      const property = column['property']
      console.log(row[property], value)
      return row[property] === value
    }
  }
}
export default Index
