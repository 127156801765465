<template>
  <div class="purchase-order">
    <div class="orderHeader flex j-between p-top-10 p-lr-30 TopTongji_line">
      <div v-if="ordderNumList.length > 0" class="hederleft flex j-between">
        <div :class="orderNumType === 0 ? orderNumClassActive : orderNumClass" @click="changeType(0)">所有订单：<span class="headerFonts p-left-10">{{ ordderNumList[0].StatisticalCount }}</span></div>
        <div :class="orderNumType === 1 ? orderNumClassActive : orderNumClass" @click="changeType(1)">待处理订单：<span class="headerFonts headerFontscolor p-left-10">{{ ordderNumList[1].StatisticalCount + ordderNumList[2].StatisticalCount }}</span></div>
        <!-- <div :class="orderNumType === 2 ? orderNumClassActive : orderNumClass" @click="changeType(2)">部分处理订单：<span class="headerFonts headerFontscolor p-left-10">{{ ordderNumList[2].StatisticalCount }}</span></div> -->
        <div :class="orderNumType === 3 ? orderNumClassActive : orderNumClass" @click="changeType(3)">已处理订单：<span class="headerFonts p-left-10">{{ ordderNumList[3].StatisticalCount }}</span></div>
        <div :class="orderNumType === 4 ? orderNumClassActive : orderNumClass" @click="changeType(4)">已退回订单：<span class="headerFonts p-left-10">{{ ordderNumList[4].StatisticalCount }}</span></div>
        <div :class="orderNumType === 5 ? orderNumClassActive : orderNumClass" @click="changeType(5)">我的订单：<span class="headerFonts p-left-10">{{ ordderNumList[5].StatisticalCount }}</span></div>
      </div>
    </div>
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <div
          v-if="$minCommon.checkMeau('cgddexport', userBottons)"
          class="ModelBttton-white-30 f12"
          @click="OrderPurchaseExport"
        >
          <i class="el-icon-upload2 f15 p-right-10" /> 导出
        </div>
      </div>
      <div class="SearchBox_30 m-left-10" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWord"
          size="medium"
          placeholder="可通过订单编号、物料号、物料名称、终端/买方/、 供应商名称"
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer cursor" @click="Adsearch">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div v-if="$minCommon.checkMeau('cgddimport', userBottons)" class="out input_file min-border flex a-center f12 m-lr-10 pointer cursor ModelBttton-white-30" @click="importFileExcle">
        <div>导入预入库单</div>
        <input ref="filElem" type="file" class="upload-file" @change="getFile">
      </div>
      <div v-if="$minCommon.checkMeau('cgdddownloadtemplate', userBottons)" class="out min-border flex a-center f12 pointer cursor ModelBttton-white-30" @click="downLoadFileTemplate">
        <div>下载导入模板</div>
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'DateCreate','BeginDate','EndDate')" />
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="IDX"
        border
        height="calc(100vh - 320px)"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" width="40" />
        <el-table-column prop="OrderPurchaseSapID" label="采购订单编号" width="130" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- v-if="$minCommon.checkMeau('cgddprewarehousing', userBottons)" -->
            <el-link v-if="$minCommon.checkMeau('cgddorderskip', userBottons)" type="primary" :underline="false" @click="() => $router.push({name:'orderDetails1', params:{ ishandler:'-1', orderid: scope.row.OrderID, Purchase: true} })">{{ scope.row.OrderPurchaseSapID }}</el-link>
            <el-link v-if="!$minCommon.checkMeau('cgddorderskip', userBottons)" type="primary" :underline="false">{{ scope.row.OrderPurchaseSapID }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="OrderID" label="供应商订单号" width="110" show-overflow-tooltip />
        <el-table-column prop="SapPR" label="采购申请编号" width="130" show-overflow-tooltip />
        <el-table-column prop="SAPSupplierCompanyName" label="供应商名称" show-overflow-tooltip />
        <el-table-column prop="SendType" label="是否直送客户" width="95" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.SendType ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="SourceOrderCompanyName" width="100" label="来源客户名称" show-overflow-tooltip />
        <el-table-column prop="CreateUserName" label="订单创建人" show-overflow-tooltip />
        <el-table-column prop="PurchaseWriterTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.PurchaseWriterTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <!-- 搜索状态：0：所有订单，1：待处理订单，2：部分处理订单，3：已处理订单，4：已退回订单，5：我的订单 -->
        <el-table-column
          prop="PurchaseStateStr"
          label="预入库状态"
          show-overflow-tooltip
        />
        <el-table-column prop="set" label="操作" width="50" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="130"
              popper-class="test_pop_view"
            >
              <!--              v-if="formSearch.SelectType !== 4"-->
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('cgddprewarehousing', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="() => $router.push({name:'order-processing', params:{OrderPurchaseSapID: scope.row.OrderPurchaseSapID, PurchaseState: scope.row.PurchaseState} })"><i class="el-icon-view p-right-10" />{{ scope.row.PurchaseState === 2 || scope.row.PurchaseState === 4 ? '查看预到货通知':'生成预到货通知' }}</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('cgdditemdelete', userBottons) && formSearch.SelectType !== 4" class="OperationButton">
                  <el-link :underline="false" @click="DelePurchaseOrder_All(scope.row)"><i class="el-icon-delete p-right-10" /> 删除订单</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :page-size="tablePageSize"
          :current-page.sync="currentPage"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 修改备注 start -->
    <el-dialog :visible.sync="showRemake" custom-class="dialog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">删除采购订单原因</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => showRemake = false">x</p>
      </div>
      <div style="height:30px" />
      <el-input
        v-model="isRemake"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 8}"
        placeholder="请输入内容"
      />
      <div style="height:30px" />
      <div class="footers min-border-top flex a-center j-center">
        <el-button size="mini" round @click="() => showRemake = false">取消</el-button>
        <el-button size="mini" type="primary" round @click="sueRemake">确定</el-button>
      </div>
    </el-dialog>
    <!-- 修改备注 end -->
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/purchasing-management/purchase-order'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
@import '@/style/order.scss';
@import "@/style/purchase.scss";
</style>
